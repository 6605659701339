import React from "react";
import { Link } from "gatsby";

const Navbar = ({ isHome }) => {
  if (isHome) {
    return (
      <div>
        <nav class='bg-white shadow z-10 fixed top-0 left-0 w-full'>
          <div class='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
            <div class='relative flex justify-between h-16'>
              <div class='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                <button
                  class='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                  aria-expanded='false'
                >
                  <span class='sr-only'>Open main menu</span>
                  <svg
                    class='block h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M4 6h16M4 12h16M4 18h16'
                    />
                  </svg>

                  <svg
                    class='hidden h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>
              <div
                className='w-screen'
                style={{
                  width: "100vw",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <ul
                  style={{
                    flex: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className='navbar-nav'
                >
                  <li
                    className='nav-item text-gray-700'
                    style={{ flexGrow: 1 }}
                  >
                    <a className='nav-link p-6 text-gray-700' href='#one'>
                      I
                    </a>
                  </li>
                  <li
                    className='nav-item text-gray-700'
                    style={{ flexGrow: 1 }}
                  >
                    <a href='#two' className='nav-link p-6 text-gray-700'>
                      II
                    </a>
                  </li>
                  <li
                    className='nav-item text-gray-700'
                    style={{ flexGrow: 1 }}
                  >
                    <a href='#three' className='nav-link p-6 text-gray-700'>
                      III
                    </a>
                  </li>
                  <li
                    className='nav-item text-gray-700'
                    style={{ flexGrow: 1 }}
                  >
                    <a className='nav-link p-6 text-gray-700' href='#four'>
                      IV
                    </a>
                  </li>
                  <li
                    className='nav-item text-gray-700'
                    style={{ flexGrow: 1 }}
                  >
                    <a href='#five' className='nav-link p-6 text-gray-700'>
                      V
                    </a>
                  </li>

                  <li className='nav-item' style={{ flexGrow: 1 }}>
                    <svg
                      class='w-6 h-6'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                      ></path>
                    </svg>
                  </li>
                </ul>
              </div>
              <div class='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                <button class='bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                  <span class='sr-only'>View notifications</span>
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M4 6h16M4 12h16M4 18h16'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  } else {
    return (
      <div>
        <nav class='bg-black bg-opacity-50 shadow z-10 fixed top-0 left-0 w-full'>
          <div className='flex justify-center w-full'>
            <a href='/'>
              <p className='text-4xl abril uppercase text-white'>deconstruct</p>
            </a>
          </div>
        </nav>
      </div>
    );
  }
};

export default Navbar;
